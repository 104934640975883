import React, { useCallback } from "react";

import css from "./final-cart.module.css";
import { TranslationContext } from "providers";
import ICartProduct from "models/ICartProduct";
import constantes from "constantes";
import { FontIcon } from "office-ui-fabric-react/lib-commonjs/Icon";

interface IFinalCartProps {
    cartProducts: ICartProduct[],
    shippingFees: string,
    updateCart: Function
}

export default function FinalCart(props: IFinalCartProps) {
    const getTotalCart = useCallback(
        function() {
            return props.cartProducts.reduce(function(acc, item) {
                return acc + item.quantity * item.product.prix;
            }, 0);
        }, [props.cartProducts]
    );

    const getTotal = useCallback(
        function() {
            const totalCart = getTotalCart();
            const shippingFees = parseFloat(props.shippingFees);

            const total = totalCart + shippingFees;
            
            return total;
        }, [props.shippingFees]
    );

    return (
        <TranslationContext.Consumer>
            {
                translationConsumer => (
                    <div className={ css.wrapper }>
                        <h1>{ translationConsumer.get("store.summaryOrder") }</h1>
                       <table>
                           <thead>
                               <tr className={ ["ms-Grid-row", "ms-lg12"].join(" ") }>
                                    <th className={ "ms-lg2" }>{ translationConsumer.get("store.article") }</th>
                                    <th className={ "ms-lg3" }>{ translationConsumer.get("store.designation") }</th>
                                    <th className={ "ms-lg3" }>{ translationConsumer.get("store.quantity") }</th>
                                    <th className={ "ms-lg2" }>{ translationConsumer.get("store.unitPrice") }</th>
                                   <th className={ "ms-lg2" }>{ translationConsumer.get("store.total") }</th>
                               </tr>
                           </thead>
                           <tbody>
                               { 
                                    props.cartProducts.map((item, index) => {
                                        const ids = item.product.id.toString().split("_");
                                        const idModele = ids[1];

                                        const value = item.product.modeles.findIndex(x => x.id === parseInt(idModele));
                                        return(
                                            <tr key={ index } className={ ["ms-Grid-row", "ms-lg12"].join(" ") }>
                                                <td className={ "ms-lg2" }><img src={`${constantes.api.origin}${item.product.illustrations[0].url}`} /></td>
                                                <td className={ "ms-lg3" }><p>{ item.product.nom + " " }{ value.toString() !== "-1" ? "(" + item.product.modeles[value].label + ")" : "" }</p></td>
                                                <td className={ "ms-lg3" }>
                                                    <p className={ css.inline }>{ item.quantity }</p>
                                                </td>
                                                <td className={ "ms-lg2" }><p>{ item.product.prix + "€" }</p></td>
                                                <td className={ "ms-lg2" }><p>{ (item.product.prix * item.quantity)  + "€" }</p></td>
                                            </tr>
                                        );
                                    })
                               }
                               {
                                   props.cartProducts.length > 0 &&
                                        <tr className={ ["ms-Grid-row", "ms-lg12"].join(" ") }>
                                            <td className={ "ms-lg2" } style={{ backgroundColor: "#F2F2F2" }}><FontIcon iconName="DeliveryTruck" style={{ fontSize: "50px" }}/></td>
                                            <td className={ "ms-lg3" } style={{ backgroundColor: "#F2F2F2" }}><p>{ translationConsumer.get("store.shippingCost") }</p></td>
                                            <td className={ "ms-lg3" } style={{ backgroundColor: "#F2F2F2" }}><p>-</p></td>
                                            <td className={ "ms-lg2" } style={{ backgroundColor: "#F2F2F2" }}><p>-</p></td>
                                            <td className={ "ms-lg2" } style={{ backgroundColor: "#F2F2F2" }}><p>{ props.shippingFees + "€" }</p></td>
                                        </tr>
                               }
                               {
                                   props.cartProducts.length > 0 &&
                                    <tr className={ ["ms-Grid-row", "ms-lg12"].join(" ") }>
                                        <td className={ "ms-lg2" } style={{ backgroundColor: "#E9E9E9" }}><FontIcon iconName="DoubleChevronLeftMedMirrored" style={{ fontSize: "50px" }}/></td>
                                        <td className={ "ms-lg3" } style={{ backgroundColor: "#E9E9E9" }}><p style={{ fontSize: "20px" }}>{ translationConsumer.get("store.total") }</p></td>
                                        <td className={ "ms-lg3" } style={{ backgroundColor: "#E9E9E9" }}><p>-</p></td>
                                        <td className={ "ms-lg2" } style={{ backgroundColor: "#E9E9E9" }}><p>-</p></td>
                                        <td className={ "ms-lg2" } style={{ backgroundColor: "#E9E9E9" }}><p>{ getTotal() + "€" }</p></td>
                                    </tr>
                               }

                           </tbody>
                       </table>
                    </div>
                )
            }
        </TranslationContext.Consumer>
    )
}
