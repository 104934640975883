import React, { useCallback } from "react";

import css from "./country-select.module.css"
import { useFetch } from "../../helpers/hooks";
import { TranslationContext } from "../../providers";
import ReactUtils from "../../helpers/ReactUtils";
import Loader from "../Loader";
import CustomDialog from "../CustomDialog";

export interface ICountry {
    name: string;
    code: string;
}

interface ICountryListProps {
    name?: string;
    onChange?: (country: ICountry) => void;
}

export const DEFAULT_COUNTRY: string = "FR";

export default function CountryList(props: ICountryListProps) {
    const { isLoading, data, error } = useFetch<Array<ICountry>>(
        `/countries.json`,
        undefined,
        []
    );

    const onChangeHandler = useCallback(
        function(event: React.ChangeEvent<HTMLSelectElement>) {
            const country: ICountry | undefined = data?.find(country => country.name == event.target.value);
            if (country) {
                props?.onChange?.(country);
            }
        }, [data]
    );

    return (
        <TranslationContext.Consumer>
            {
                translationConsumer => (
                    ReactUtils.either(
                        isLoading,
                        <Loader className={ css["loader-container"] } />,
                        ReactUtils.neither(
                            error == null,
                            <CustomDialog title={ error ?? "" } description={ translationConsumer.get("dialog.technicalError") } />,
                            <select name={ props.name } onChange={ onChangeHandler }>
                                {
                                    data?.map(country => {
                                        return <option key={ country.code } value={ country.name } selected={ country.code == DEFAULT_COUNTRY }>{ country.name }</option>;
                                    })
                                }
                            </select>
                        )
                    )
                )
            }
        </TranslationContext.Consumer>
    );
}
