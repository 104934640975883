import React, { useCallback, useContext } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { SearchBox } from "office-ui-fabric-react/lib-commonjs/SearchBox";
import { Toggle } from "office-ui-fabric-react/lib-commonjs/Toggle";
import { FontIcon } from "office-ui-fabric-react/lib-commonjs/Icon";

import headerJpg from "../../assets/header.jpg";
import css from "./header.module.css";
import { ConfigurationContext, LanguageContext, TranslationContext, Language } from "../../providers";
import routes from "../../routes";
import { Translation } from "../Translation";
import SocialNetwork from "../SocialNetwork";

export default function Header() {
    const configurationConsumer = useContext(ConfigurationContext);
    const languageConsumer = useContext(LanguageContext);
    const translationConsumer = useContext(TranslationContext);

    const location = useLocation();
    const history = useHistory();

    const handleSearch = useCallback(
        function (keywords: string) {
            if (keywords?.length) {
                const searchRoute: string = routes.search.split("/").splice(0, 2).join("/");
                history.replace(`${searchRoute}/${encodeURI(keywords)}`);
            }
        }, []
    );

    const handleLocaleChange = useCallback(
        function (value: string) {
            languageConsumer.set(value);
        }, []
    );

    const switchToFrench = useCallback(
        function () {
            handleLocaleChange(Language.French);
        }, []
    );

    const switchToEnglish = useCallback(
        function () {
            handleLocaleChange(Language.English);
        }, []
    );

    const handleEventLocaleChange = useCallback(
        function (_: React.MouseEvent<HTMLElement>, checked?: boolean): void {
            if (checked) {
                switchToEnglish();
            } else {
                switchToFrench();
            }
        }, []
    );

    const highlightCurrentRoute = useCallback(
        function (pathname: string, links: Array<{to: string, exactMatch: boolean, i18nKey: string, beforeBody?: React.ReactNode}>) {
            return links.map(function(link){
                if ((link.exactMatch && pathname == link.to) || (!link.exactMatch && pathname.startsWith(link.to))) {
                    return <Link key={ link.to } className={ css["current-route"] } to={ link.to } title={ translationConsumer.get(link.i18nKey) }>
                        {
                            link.beforeBody
                        }

                        <span>
                            <Translation t={ translationConsumer.t } i18nKey={ link.i18nKey } />
                        </span>
                    </Link>
                } else {
                    return <Link key={ link.to } to={ link.to } title={ translationConsumer.get(link.i18nKey) }>
                        {
                            link.beforeBody
                        }

                        <span>
                            <Translation t={ translationConsumer.t } i18nKey={ link.i18nKey } />
                        </span>
                    </Link>
                }
            });
        }, []
    );

    // if (__BROWSER__) {
    //     stickybits("." + css["sticky-header"]);
    // }

    return (
        <React.Fragment>
            <header className={ css.wrapper }>
                <div className={ css["fix-header"] }>
                    <nav>
                        <div className="ms-Grid" dir="ltr">
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg3" style={{ padding: "10px 16px" }}>
                                    <SearchBox
                                        /*styles={{ root: { width: 200, position: "relative", top: "12px", left: "14px" } }}*/
                                        placeholder={ translationConsumer.get("header.search") }
                                        onSearch={ handleSearch } />
                                </div>
                                <div className="ms-Grid-col ms-hiddenMdDown ms-lg4"></div>
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg5" style={{ padding: "10px 16px", filter: "sepia(20%)" }}>
                                    <div className="ms-Grid">
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                                <SocialNetwork />
                                            </div>

                                            <LanguageContext.Consumer>
                                                {
                                                    locale => (
                                                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6" style={{ marginTop: "4px" }}>
                                                            <div className="ms-Grid">
                                                                <div className="ms-Grid-row">
                                                                    <div className="ms-Grid-col ms-lg-4">
                                                                        <img className={ [css.flag, css.leftFlag].join(" ") } src="https://image.flaticon.com/icons/svg/197/197560.svg" title="Français" onClick={ switchToFrench } />
                                                                    </div>
                                                                    <div className="ms-Grid-col ms-lg-4">
                                                                        <Toggle onChange={ handleEventLocaleChange } checked={ locale.value === "en_US" } styles={{ container: { marginTop: "3px" }, pill: { marginLeft: "15px" } }} />
                                                                    </div>
                                                                    <div className="ms-Grid-col ms-lg-4">
                                                                        <img className={ [css.flag, css.rightFlag].join(" ") } src="https://image.flaticon.com/icons/svg/323/323329.svg" title="English" onClick={ switchToEnglish } />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </LanguageContext.Consumer>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>
                <div className={ css.illustration }>
                    <img src={ headerJpg } />
                </div>
            </header>

            <nav className={ css["sticky-header"] }>
                {
                    highlightCurrentRoute(
                        location.pathname,
                        [
                            {
                                to: "/",
                                exactMatch: true,
                                i18nKey: "header.nav.home",
                                beforeBody: <FontIcon iconName="HomeSolid" />
                            },
                            {to: "/events", exactMatch: false, i18nKey: "header.nav.events"},
                            {to: "/restauration", exactMatch: false, i18nKey: "header.nav.restauration"},
                            {to: "/audio-tour", exactMatch: false, i18nKey: "header.nav.audioTour"},
                            {to: "/history-of-the-circuit", exactMatch: false, i18nKey: "header.nav.historyOfTheCircuit"},
                            {to: "/membership", exactMatch: true, i18nKey: "header.nav.membership"},
                            {to: "/sponsors", exactMatch: true, i18nKey: "header.nav.sponsors"},
                            {to: "/contact", exactMatch: true, i18nKey: "header.nav.contact"}
                        ].concat(
                            configurationConsumer.boutique.accessible
                                ? [{
                                    to: "/store",
                                    exactMatch: true,
                                    i18nKey: "header.nav.store",
                                    beforeBody: <FontIcon iconName="ShoppingCartSolid" />
                                }]
                                : []
                        )
                    )
                }
            </nav>
        </React.Fragment>
    );
}
